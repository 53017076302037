<template>
    <div class="home" style="background-color: #fdfdfd;overflow-y: auto;">
      <van-cell-group   style="background-color: #1c51a3; padding: 10px;margin-bottom: 10px;" inset>
        <van-image style=" float: left;height: 50px"
            width="50"
            height="50"
            :src="apkhubLogo"
        />
        <span style="
        font-size: large;
        height: 50px;
        display: inline-block;
        color: white;
        float: left;
        font-weight: bold;
        padding-left: 10px;
        padding-top: inherit;">
          APKHUB-全球APK下载社区</span>
      </van-cell-group>

        <van-cell-group class="cellcard" style="line-height:30px;height: 85vh;overflow-y: auto;font-size: 0.33rem;" inset>
            <div style="color: rgb(25 137 250); font-weight: 700;text-align: center;font-size: 0.45rem;padding-bottom:5px;">
                <span style="font-family: monospace;height: 80vh;overflow-y: auto;margin-top: 10px">讲不出再见</span>
            </div>
            <div class="van-hairline--top"></div>
            <p style="text-indent: 18px;margin-top:5px;margin-bottom:5px;line-height: 1.5">APKHub 运营遇到资金困难，在没有明确的盈利方案，并坚持免费提供下载服务的情况下，每月成本巨大，难以为继，迫切需要大家的支持。</p>

            <div class="van-hairline--top"></div>
            <p style="text-indent: 18px;margin-bottom:0px;line-height: 1.5">
                平台成立四年来，我们取得了一些成绩，尽心服务大家，但不能提供持续的保障。如今，面对社区的未来，我们正步履维艰。</p>

            <div class="van-hairline--top"></div>
            <div>
                <div style="margin-top:10px;text-align: center; " >
                    <div style="display: inline-block;margin-right: 10px;">
                        <van-circle v-model="currentRate1" layer-color="gray"  size="60px"  color="#fdaf00" :stroke-width="60" :rate="40" :speed="100" text="369万" style="margin-left:10px;" />
                        <div >用户数</div>
                    </div>
                    <div style="display: inline-block;margin-right: 10px;">
                        <van-circle v-model="currentRate2" layer-color="gray" size="60px" color="green"  :stroke-width="60" :rate="60" :speed="100" text="4684万"   />
                        <div >下载次数</div>
                    </div>

                    <div style="display: inline-block;margin-right: 10px;">
                        <van-circle v-model="currentRate3" layer-color="gray" size="60px"   color="#7f07d9" :stroke-width="60" :rate="60" :speed="100" text="6.1W TB" />
                        <div >消耗流量</div>
                    </div>
                    <div style="display: inline-block;margin-right: 10px;">
                        <van-circle v-model="currentRate4" layer-color="gray" size="60px"  color="#e75500"  :stroke-width="60" :rate="85" :speed="100" text="8359万"   />
                        <div >点赞数</div>
                    </div>
                </div>
            </div>
            <div class="van-hairline--top"></div>

            <p  v-if="!showqQr" style="text-indent: 18px;margin-bottom:0px;line-height: 1.5">我们的成绩不是炫耀，而是团队共同承担的压力。为了能让平台持续运营，在此我们发起乐捐计划(<span style="font-weight: 700;">10元/人</span>)。
                此次募集资金是为了维持APKHub持续运营，也希望大家都能为APKHub出一份力，共同参与，共同维护。
            </p>
            <div class="van-hairline--top"></div>
            <p  v-if="!showqQr" style="text-indent: 18px;margin-bottom:0px;line-height: 1.5;color:red;">注意：若此次募集资金总额无法支撑下个月的服务器和流量费用，则有可能暂时关闭APKHub平台，
                但会赠送第三方VPN(影子)作为补偿，并免费提供体验时长。
            </p>
            <div  v-if="!showqQr" style="font-size: 0.33rem;margin-top:10px;font-weight: 700;" >影子官方TG群：
            </div>
            <div v-if="!showqQr" style="text-indent: 20px;text-decoration: underline;color: rgb(0 80 239);font-weight: 700;display: inline-block;" @click="copyLinks()">点我复制链接</div>
            <div v-if="!showqQr" style="text-indent: 20px;display: inline-block;">
                <a style="text-decoration: underline;color: rgb(0 80 239);font-weight: 700;" href="https://t.me/+egDKJw_6oOQzMGJl">点我一键加入</a>
            </div>
            <p  v-if="showqQr" style="text-indent: 18px;margin-bottom:0px;line-height: 1.5">请使用支付宝完成乐捐扫码，你输入的邮箱号即为新应用的户用名，或者直接联系新应用的客服。
            <a href="https://t.me/+egDKJw_6oOQzMGJl" style="color:blue;text-decoration: underline;margin-top:1rem;">点击加入TG群</a></p>

            <div class="van-hairline--top"></div>
            <div v-if="showqQr" style="margin-top: 15px"  >
                <center>
                    <span >请使用支付宝扫码乐捐<van-loading style="display:inline;" color="#1989fa;"  size="24px" /></span>
                    <br>
                    <div  class="qrClass" style="padding-bottom:80px;"> <img  style="align-items: center" :src="qrCodeDataUrl" alt="QR Code" /></div>

                </center>
            </div>


            <van-form v-if="!showqQr"  style="margin-top:8px;">
                请填写APKHub绑定的邮箱，点击提交即可乐捐。
                <van-field
                        v-model="username"
                        name="APKhub邮箱"
                        label="邮箱地址"
                        placeholder="APKhub绑定的邮箱地址"
                        :rules="[{ required: true, message: '请填写APKhub绑定的邮箱地址' }]"
                />

                <div style="margin: 16px;">
                    <van-button round block type="info" @click="submitapkhub">提交</van-button>
                </div>
            </van-form>
        </van-cell-group>



        <van-overlay :show="showDone" @click="showDone = false">
            <div class="wrapper" @click.stop>
                <div style="align-items: center">
                    <center><img style="align-items: center;width: 60%" src="../../assets/img/d1.png"></center>
                </div>
            </div>
        </van-overlay>




    </div>
</template>

<style>
    *{
        font-family: math;
    }
    .van-circle__layer {
        stroke: #e2e6eb;
    }
</style>
<script>
import { initApkHub,queryApkHub } from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';

Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);
import  apkhubLogo  from "../../assets/img/APKHub.png";
export default {
  data () {
    return {
      sbtShow:false,
      qrCodeDataUrl: '', // 生成的二维码 URL
      showqQr : false,
      showDone:false,
      apkhubLogo:apkhubLogo,
      currentRate: 0,
      currentRate1: 10,
      currentRate2: 20,
      currentRate3: 30,
      currentRate4: 40,
      username: '',
      password: '',
      newOrderId:null,
      timer: null       // 保存定时器的 ID
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
       // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  methods: {
    generateQRCode(text) {
      QRCode.toDataURL( text, { width: 200, height: 200 })
          .then((url) => {
            this.qrCodeDataUrl = url; // 将生成的二维码 URL 赋值给 qrCodeDataUrl
            this.showqQr =true;
          })
          .catch((err) => {
            console.error(err);
          });
    },
      copyLinks(){
          document.oncopy = function (e) {
              e.clipboardData.setData('text', 'https://t.me/+egDKJw_6oOQzMGJl');
              e.preventDefault();
              document.oncopy = null;
          }
          document.execCommand('Copy');
          Notify({ type: 'success', message: '复制成功' });
      },
    async submitapkhub() {
      this.sbtShow = true;
      Notify({ type: 'primary', message: '提交中请勿刷新或重复提交' });
      var qrData = await initApkHub(this.username);
      if(qrData.code!=200){
        Toast({
          message: qrData.msg,
          icon: apkhubLogo,
        });
        //
        // this.generateQRCode('https://www.baidu.com/?=没搞定啊');
        // this.newOrderId=135;
      }else{
        this.generateQRCode(qrData.data.payData)
      }
     // console.log('qrData',qrData);
    },
    startTimer() {
      // 如果已有定时任务，先清除之前的定时器
      if (this.timer) {
        clearInterval(this.timer);
      }

      // 启动定时任务
      this.timer = setInterval(async () => {
      //  console.log('执行定时任务，newOrderId:', this.newOrderId);
        // 在这里执行你想要的逻辑
      var order =   await queryApkHub(this.newOrderId)
       // console.log("order",order)
        if(order.code==200 && order.data.payState == 'paied'){
          this.stopTimer();
          this.showDone=true;
        }
      }, 10000);  // 每秒执行一次
    },
    stopTimer() {
      // 停止定时任务
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
       // console.log('定时任务已停止');
      }
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    this.stopTimer();
  },

}
</script>

<style lang="less" scoped>
.home{
  background-image: url("../../assets/img/bg.png");
  padding: 10px;
  padding-left: 1px;
  padding-right: 1px;
  height: 100%;
 // background: radial-gradient(lightskyblue, lightskyblue, green);
}
.cellcard{
  padding: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  //background-image: url("../../assets/img/d2.jpeg");
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)), url(../../assets/img/d2.jpeg);
  background-size: cover;
}
.qrClass{
  width: 100%;align-items: center;
  background-image: url("../../assets/img/zfb.png");
}


.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
